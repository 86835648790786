<template>
  <div class="mx-1">
    <div class="mb-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        :to="{ name: 'tournament-add' }"
      >
        Create Tournament
      </b-button>

    </div>

    <b-card-group deck>

      <b-card
        v-for="( tournament, index) in tournaments"
        :key="index"
        no-body
        @click="$router.push({ name: 'tournament-details', params: { tournament: tournament.slug } })"
      >
        <b-img-lazy
          fluid
          class="card-img-top"
          src="/images/bgmi-tournament.jpg"
          width="200"
          height="100"
        />
        <b-card-body class="p-1">
          <!-- metting header -->

          <b-card-title class="mt-n3 mx-n1 px-1 bg-transparent-purple text text-truncate text-white">
            {{ tournament.name }}
          </b-card-title>
          <!--/ metting header -->

          <!-- media -->
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-success"
                size="34"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ tournament.registration_starts_at.date }}
              </h6>
              <small>{{ tournament.registration_starts_at.time }}</small>
            </b-media-body>
          </b-media>
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-danger"
                size="34"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ tournament.tournament_ends_at.date }}
              </h6>
              <small>{{ tournament.tournament_ends_at.time }}</small>
            </b-media-body>
          </b-media>

          <!-- avatar group -->
          <b-avatar-group
            v-if="tournament.registrations > 0"
            size="33"
          >
            <b-avatar
              v-for="(avatar, i) in tournament.avatars"
              :key="i"
              v-b-tooltip.hover.bottom="avatar.name"
              :src="avatar.image"
              class="pull-up"
            />
            <h6
              v-if="(tournament.registrations - tournament.avatars.length) > 0"
              class="align-self-center cursor-pointer ml-1 mb-0"
            >
              +{{ tournament.registrations - tournament.avatars.length }}
            </h6>
          </b-avatar-group>
          <div
            v-else
            class="d-none"
          >
            No registrations yet, would you like to promote your tournament?
          </div>

        </b-card-body>

      </b-card>
    </b-card-group>
    <b-pagination
      v-if="items_total > items_per_page"
      v-model="page"
      pills
      :total-rows="items_total"
      :per-page="items_per_page"
      @input="loadData"
    />
  </div>

</template>

<script>
import {
  BCardGroup, BCard, BImgLazy, BCardBody, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BButton, BPagination,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardGroup,
    BCard,
    BImgLazy,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BButton,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      tournaments: [],
      page: 1,
      items_total: 1,
      items_per_page: 1,
    }
  },
  beforeCreate() {
    this.$http.get(`/organizer/tournaments?organization=${localStorage.getItem('organizationSelected')}`).then(res => {
      this.tournaments = res.data.data.tournaments
      this.items_total = res.data.data.meta.total
      this.items_per_page = res.data.data.meta.per_page
    })
  },
  methods: {
    async loadData() {
      this.$http.get(`/organizer/tournaments?organization=${localStorage.getItem('organizationSelected')}&page=${this.page}`).then(res => {
        this.videos = res.data.data.tournaments
        this.items_total = res.data.data.meta.total
        this.items_per_page = res.data.data.meta.per_page
      })
    },
  },

}
</script>
